import { useStaticQuery } from "gatsby";
import { default as Image } from "gatsby-image";
import * as React from "react";
import { Mutation } from "react-apollo";
import { UPDATE_USER } from "../../gatsby-theme-apollo/operations";
import RadioOff from "../../img/svg/radio-off.inline.svg";
import RadioOn from "../../img/svg/radio-on.inline.svg";
import styles from "./Question.module.scss";

export interface IQuestionProps {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  step: number;
  question: string;
  answers: string[];
  rightAnswerIndex: number;
  messageOk: string;
  messageNotOK: string;
  motivation: string;
  userData?: any;
  setUserData?: (userData: any) => void;
}

const Question = ({
  goToNextStep,
  goToPreviousStep,
  step,
  question,
  answers,
  rightAnswerIndex,
  messageOk,
  messageNotOK,
  motivation,
  userData,
  setUserData,
}: IQuestionProps) => {
  const [radio, setRadio] = React.useState<number>(null);
  const [answerOk, setAnswerOk] = React.useState<boolean>(null);
  const [clicked, setClicked] = React.useState<boolean>(false);
  const { radioOn, radioOff, arrowLeft, arrowRight, logo } = useStaticQuery(
    imgQuery
  );

  const [error, setError] = React.useState<any>(null);
  console.log(error);

  return (
    <div className={styles.container}>
      <div className={styles.greenColumn}>
        <div className={styles.title}>{`${step}/10`}</div>
        <div className={styles.text}>{question}</div>
      </div>

      <div className={styles.whiteColumn}>
        {answers.map((answer, i) => (
          <div key={i} className={styles.rowContainer}>
            <div className={styles.row}>
              <div
                className={
                  radio === i
                    ? styles.radioContainerChecked
                    : styles.radioContainer
                }
                onClick={() => {
                  !answerOk ? setRadio(i) : undefined;
                  setClicked(false);
                }}
              >
                {radio === i ? (
                  <RadioOn className={styles.radioOn} />
                ) : (
                  <RadioOff className={styles.radioOff} />
                )}
                <label className={styles.radioLabel}>{answer}</label>
              </div>
            </div>
          </div>
        ))}

        {clicked && (
          <div className={answerOk ? styles.messageOk : styles.messageNotOk}>
            {radio === null ? (
              "Sélectionnez une réponse pour continuer"
            ) : answerOk ? (
              messageOk
            ) : (
              <div>
                <span>{messageNotOK}</span>
                <span style={{ fontWeight: "bold" }}>{motivation}</span>
              </div>
            )}
          </div>
        )}

        <div className={styles.buttonRow}>
          {step > 1 && (
            <button
              className={styles.backButton}
              onClick={() => {
                goToPreviousStep();
              }}
            >
              <Image fixed={arrowLeft.childImageSharp.fixed} />
            </button>
          )}
          {!answerOk && (
            <button
              className={styles.continueButton}
              onClick={() => {
                setAnswerOk(radio === rightAnswerIndex ? true : false);
                setClicked(true);
              }}
            >
              Continuer
            </button>
          )}
          {answerOk && (
            <Mutation
              mutation={UPDATE_USER}
              context={{}}
              onCompleted={(data: any) => {
                goToNextStep();
                setUserData(data.updateUser.user);
                console.log("dataUpdated", data.updateUser);
              }}
              onError={(error: any) => {
                setError(JSON.parse(JSON.stringify(error)));
              }}
            >
              {(updateUser: any, result: any) => {
                const { loading } = result;
                return (
                  <button
                    className={styles.nextButton}
                    onClick={() => {
                      step === 10
                        ? updateUser({
                            variables: {
                              updateUserInput: {
                                id: userData.id,
                                quizzCompleted: true,
                              },
                            },
                          })
                        : goToNextStep();
                    }}
                  >
                    <span style={{ paddingLeft: 20 }}>
                      {step === 10 ? "Terminer" : "Question suivante"}
                    </span>
                    {<Image fixed={arrowRight.childImageSharp.fixed} />}
                  </button>
                );
              }}
            </Mutation>
          )}
        </div>

        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <Image fluid={logo.childImageSharp.fluid} />
          </div>
          <div className={styles.pagination}>{`${step}/10`}</div>
        </div>
      </div>
    </div>
  );
};

export default Question;

export const imgQuery = graphql`
  query ImgQuery {
    radioOn: file(relativePath: { eq: "radio-on.jpg" }) {
      childImageSharp {
        fixed(width: 22) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    radioOff: file(relativePath: { eq: "radio-off.jpg" }) {
      childImageSharp {
        fixed(width: 22) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    arrowLeft: file(relativePath: { eq: "arrow-left.jpg" }) {
      childImageSharp {
        fixed(width: 22) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    arrowRight: file(relativePath: { eq: "arrow-right.jpg" }) {
      childImageSharp {
        fixed(width: 22) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    logo: file(relativePath: { eq: "logo-color.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
