import { useStaticQuery } from "gatsby";
import { default as Image } from "gatsby-image";
import * as React from "react";
import styles from "./Finalisation.module.scss";

export interface IFinalisationProps {}

const Finalisation = ({}: IFinalisationProps) => {
  const { logo } = useStaticQuery(imgQry);

  return (
    <div className={styles.container}>
      <div className={styles.greenColumn}>
        <div
          className={styles.title}
        >{`Merci à toutes et à tous pour votre participation !`}</div>

        <div className={styles.text}>{``}</div>
      </div>

      <div className={styles.whiteColumn}>
        <div
          className={styles.text}
          style={{ marginBottom: 20, marginTop: 10 }}
        >
          {`Le quiz Lime Allianz est désormais terminé !`}
        </div>
        <div className={styles.text} style={{ marginBottom: 20 }}>
          Pour les gagnant(e)s, munissez-vous de votre mail de confirmation et
          de votre certificat de conduite en allant chercher votre casque* (sous
          réserve de disponibilité des casques) dans une agence Allianz
          participante à l’opération (
          <a
            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.allianz.fr_content_dam_onemarketing_azfr_common_marque_pdf_Fichier-5Fagents-5FVF.pdf&d=DwMFaQ&c=4JOzdpIlQcN5fHQL_PMhCQ&r=4qIPBw4Yl9nojlUxt8TLnzA_iZBPp6XICpXkjEqwgWs&m=Ul0Lvol_5snwcyZYPK05_VGSslLFzEByPfzPholIvGc&s=VuDfmyy8GGTgbtLcC6tzqqZnpgz23W4oA_UdUNiNndw&e="
            target="_blank"
          >
            <span className={styles.hyperlink}>
              voir liste des agences Allianz participantes
            </span>
          </a>
          ).
        </div>
        <div className={styles.text} style={{ marginBottom: 20 }}>
          {`À bientôt !`}
        </div>
        <div className={styles.smallText} style={{ marginBottom: 20 }}>
          {`*Les casques Allianz x LIME ne sont adaptés qu’à un usage sur trottinette électrique et non sur e-scooter. Dans la limite des stocks disponibles.`}
        </div>
        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <Image fluid={logo.childImageSharp.fluid} />
          </div>
          <div className={styles.pagination}>{` `}</div>
        </div>
      </div>
    </div>
  );
};

export default Finalisation;

export const imgQry = graphql`
  query ImgQry {
    logo: file(relativePath: { eq: "logo-color.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
