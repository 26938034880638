import { Mutation } from "@apollo/react-components";
import { useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { CREATE_USER } from "../../gatsby-theme-apollo/operations";
import Logo from "../../img/svg/logo-color.inline.svg";
import styles from "./Inscription.module.scss";

export interface IInscriptionProps {
  goToNextStep: () => void;
  setUserData: (userData: any) => void;
}

const Inscription = ({ goToNextStep, setUserData }: IInscriptionProps) => {
  //////////////////////////////////////////////////////////////////////// QUERY AND MUTATIONS
  const [email, setEmail] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [optinLimePolicy, setOptinLimePolicy] = React.useState<boolean>(false);
  const [optinLimeMarketing, setOptinLimeMarketing] = React.useState<boolean>(
    false
  );
  const [optinAllianzPolicy, setOptinAllianzPolicy] = React.useState<boolean>(
    false
  );
  const [optinAllianzMarketing, setOptinAllianzMarketing] = React.useState<
    boolean
  >(false);
  const [optinLimeAllianzPolicy, setOptinLimeAllianzPolicy] = React.useState<
    boolean
  >(false);

  const [error, setError] = React.useState<any>(null);
  const [errorMessage, setErrorMessage] = React.useState<any>(null);

  const { logo } = useStaticQuery(inscriptionImgQuery);

  React.useEffect(() => {
    if (error) {
      if (error.message === "Email already exists")
        setErrorMessage(
          "Cet e-mail existe déjà. Vous avez très probablement déjà participé."
        );
      else if (error.message === "Bad Request Exception")
        setErrorMessage(
          error.graphQLErrors[0].extensions.exception.response.message[0]
        );
      else if (
        error.message === "Response not successful: Received status code 400"
      )
        setErrorMessage(error.networkError.result.errors[0].message);
      else setErrorMessage(error.message);
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <div className={styles.greenColumn}>
        <div className={styles.title}>Quiz de bonne conduite Lime Allianz</div>
        <div className={styles.text}>
          Répondez à ce rapide quiz après avoir regardé la vidéo sur la sécurité
          en trottinette électrique afin d’obtenir votre certificat de bonne
          conduite. Un casque vous attend dans les agences Allianz participantes
          à l’opération !
        </div>
        <div /* className={styles.footer} */ style={{ visibility: "hidden" }}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} />
          </div>
        </div>
      </div>

      <div className={styles.whiteColumn}>
        <Mutation
          mutation={CREATE_USER}
          context={{}}
          onCompleted={(data: any) => {
            goToNextStep();
            setUserData(data.createUser.user);
          }}
          onError={(error: any) => {
            setError(JSON.parse(JSON.stringify(error)));
          }}
        >
          {(createUser: any, result: any) => {
            const { loading } = result;
            return (
              <form
                name="REGISTRATION_FORM"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  createUser({
                    variables: {
                      createUserInput: {
                        email: email.toLowerCase(),
                        firstName,
                        lastName,
                        phoneNumber,
                        optinLimePolicy,
                        optinLimeMarketing,
                        optinAllianzPolicy,
                        optinAllianzMarketing,
                        optinLimeAllianzPolicy,
                        quizzCompleted: false,
                      },
                    },
                  });
                }}
              >
                <div className={styles.rowContainer}>
                  <div className={styles.row}>
                    <input
                      id="firstName"
                      className={`${styles.input} ${styles.inputLeft}`}
                      name="firstName"
                      type="text"
                      placeholder="Saisir votre prénom"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      className={`${styles.input}`}
                      name="lastName"
                      type="text"
                      placeholder="Saisir votre nom"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.rowContainer}>
                  <div className={styles.row}>
                    <input
                      type="email"
                      name="email"
                      className={`${styles.input}`}
                      placeholder="Saisir votre email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.rowContainer}>
                  <div className={styles.row}>
                    <input
                      type="text"
                      className={`${styles.input}`}
                      name="phoneNumber"
                      placeholder="Saisir votre numéro de téléphone"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.checkboxGroup}>
                  <div
                    className={styles.checkboxRow}
                    onClick={() => setOptinLimePolicy(!optinLimePolicy)}
                  >
                    <input
                      type="checkbox"
                      className={`${styles.checkbox}`}
                      name="optinLimePolicy"
                      checked={optinLimePolicy}
                      //onChange={(e) => setOptinLimePolicy(e.target.checked)}
                    />
                    <label className={styles.checkboxLabel}>
                      J'ai lu et pris connaissance de la{" "}
                      <a
                        href="http://www.li.me/privacy?__hstc=5909356.5cb6d821d393c4c81919cfee29db9372.1611325326226.1611325326226.1611325326226.1&amp;__hssc=5909356.1.1611325326227&amp;__hsfp=3285655468"
                        target="_blank"
                      >
                        politique de confidentialité de Lime
                      </a>
                      .
                    </label>
                  </div>

                  <div
                    className={styles.checkboxRow}
                    onClick={() => setOptinLimeMarketing(!optinLimeMarketing)}
                  >
                    <input
                      type="checkbox"
                      className={`${styles.checkbox}`}
                      name="optinLimeMarketing"
                      checked={optinLimeMarketing}
                    />
                    <label className={styles.checkboxLabel}>
                      J'accepte de recevoir des communications électroniques
                      liées au marketing de Lime, y compris des nouvelles, des
                      événements, des mises à jour et des e-mails promotionnels
                      concernant ce produit et d'autres produits et services de
                      Lime. Je peux retirer mon consentement et me désabonner de
                      ces communications à tout moment, par exemple, en cliquant
                      sur le lien de désabonnement inclus dans les e-mails.
                    </label>
                  </div>

                  <div
                    className={`${styles.checkboxRow}`}
                    onClick={() => setOptinAllianzPolicy(!optinAllianzPolicy)}
                  >
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      name="optinAllianzPolicy"
                      checked={optinAllianzPolicy}
                    />
                    <label className={styles.checkboxLabel}>
                      J'ai lu et j'accepte la{" "}
                      <a
                        href="https://www.allianz.fr/assurance-particulier/protection-donnees-personnelles.html"
                        target="_blank"
                      >
                        {" "}
                        politique de confidentialité d'Allianz
                      </a>
                      .
                    </label>
                  </div>

                  <div
                    className={styles.checkboxRow}
                    onClick={() =>
                      setOptinAllianzMarketing(!optinAllianzMarketing)
                    }
                  >
                    <input
                      type="checkbox"
                      className={`${styles.checkbox}`}
                      name="optinAllianzMarketing"
                      checked={optinAllianzMarketing}
                    />
                    <label className={styles.checkboxLabel}>
                      J'accepte de recevoir des communications électroniques
                      liées au marketing de la part d'Allianz, y compris des
                      nouvelles, des événements, des mises à jour et des e-mails
                      promotionnels concernant ce produit et d'autres produits
                      et services d'Allianz. Je peux retirer mon consentement et
                      me désabonner de ces communications à tout moment, par
                      exemple, en cliquant sur le lien de désabonnement inclus
                      dans les e-mails.
                    </label>
                  </div>

                  <div
                    className={styles.checkboxRow}
                    onClick={() =>
                      setOptinLimeAllianzPolicy(!optinLimeAllianzPolicy)
                    }
                  >
                    <input
                      type="checkbox"
                      className={`${styles.checkbox}`}
                      name="optinLimeAllianzPolicy"
                      checked={optinLimeAllianzPolicy}
                    />
                    <label className={styles.checkboxLabel}>
                      En cliquant sur Démarrer, vous acceptez{" "}
                      <a
                        href="https://www.allianz.fr/content/dam/onemarketing/azfr/common/marque/pdf/TermesConditions_Lime_VF.pdf"
                        target="_blank"
                      >
                        les conditions de la politique de confidentialité
                      </a>{" "}
                      de Lime &amp; Allianz.
                    </label>
                  </div>
                  {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                  )}
                </div>
                <div>
                  <button
                    className={styles.startButton}
                    type="submit"
                    // disabled={submitting}
                  >
                    Démarrer
                  </button>
                </div>
              </form>
            );
          }}
        </Mutation>

        {/*         <div className={styles.footer}>
          <div
            className={styles.logoContainer}
            style={{ width: 350, height: 100, backgroundColor: "blue" }}
          >
            <Logo className={styles.logo} />
          </div>
        </div> */}

        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <Img fluid={logo.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inscription;

export const inscriptionImgQuery = graphql`
  query InscriptionImgQuery {
    logo: file(relativePath: { eq: "logo-color.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
