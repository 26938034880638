import { useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import * as React from "react";
import styles from "./Confirmation.module.scss";

export interface IConfirmationProps {
  goToNextStep: () => void;
}

const Confirmation = ({ goToNextStep }: IConfirmationProps) => {
  const { iconTwitter, iconFacebook, iconMail, logo } = useStaticQuery(
    confirmationImgQuery
  );

  return (
    <div className={styles.container}>
      <div className={styles.greenColumn}>
        <div className={styles.title}>{`Vous êtes certifié(e) ! 🎉🛴🎓`}</div>
        <div
          className={styles.text}
        >{`Votre certificat de sécurité vous sera envoyé par e-mail très prochainement. Félicitations pour avoir réussi le quiz Lime Allianz sur la sécurité en trottinette électrique.`}</div>
      </div>

      <div className={styles.whiteColumn}>
        <div className={styles.text}>{`Félicitations !`}</div>

        <div className={styles.text}>
          {`Vous êtes désormais prêts à profiter de 15 minutes de free-ride* LIME : `}
          <a href="https://limebike.app.link/TgLvcc9Taeb" target="_blank">
            <span className={styles.hyperlink}>C’est parti !</span>
          </a>
        </div>
        <div className={styles.text}>
          Afin de rouler en tout sécurité, vous pouvez aller chercher votre
          casque** dans une agence Allianz participante à l’opération (
          <a
            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.allianz.fr_content_dam_onemarketing_azfr_common_marque_pdf_Fichier-5Fagents-5FVF.pdf&d=DwMFaQ&c=4JOzdpIlQcN5fHQL_PMhCQ&r=4qIPBw4Yl9nojlUxt8TLnzA_iZBPp6XICpXkjEqwgWs&m=Ul0Lvol_5snwcyZYPK05_VGSslLFzEByPfzPholIvGc&s=VuDfmyy8GGTgbtLcC6tzqqZnpgz23W4oA_UdUNiNndw&e="
            target="_blank"
          >
            <span className={styles.hyperlink}>
              voir liste des agences Allianz participantes
            </span>
          </a>
          ) grâce au certificat qui vous a été envoyé par mail.
        </div>
        <div className={styles.text}> {`Partagez ce quiz avec vos amis !`}</div>

        <div className={styles.row}>
          <a
            className={styles.icon}
            href="https://twitter.com/intent/tweet?via=limebike&amp;text=Tentez+de+gagner+un+casque+gratuit+Allianz+Lime+en+r%C3%A9pondant+au+quiz+de+bonne+conduite+sur+les+trottinettes+%C3%A9lectriques+%40allianzfrance+&amp;url=https%3A%2F%2Fwww.allianz.fr%2Fqui-est-allianz%2Fnous-connaitre%2Fallianz-innove%2Fallianz-lime-partenariat.html"
            target="_blank"
          >
            <Image fixed={iconTwitter.childImageSharp.fixed} />
          </a>
          <a
            className={styles.icon}
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.allianz.fr%2Fqui-est-allianz%2Fnous-connaitre%2Fallianz-innove%2Fallianz-lime-partenariat.html&amp;quote=Je+viens+de+passer+le+quiz+de+s%C3%A9curit%C3%A9+d%27Allianz+et+Lime%2C+et+je+suis+maintenant+en+route+pour+r%C3%A9cup%C3%A9rer+mon+casque+gratuit+%21+R%C3%A9pondez+au+quiz+sur+la+s%C3%A9curit%C3%A9+ici+"
            target="_blank"
          >
            <Image fixed={iconFacebook.childImageSharp.fixed} />
          </a>
          <a
            className={styles.icon}
            href="mailto:?subject=Je%20suis%20maintenant%20en%20route%20pour%20récupérer%20mon%20casque%20gratuit%20!&amp;body=Je%20viens%20de%20passer%20le%20quiz%20de%20s%C3%A9curit%C3%A9%20d%27Allianz%20et%20Lime%2C%20et%20je%20suis%20maintenant%20en%20route%20pour%20r%C3%A9cup%C3%A9rer%20mon%20casque%20gratuit%20%21%20R%C3%A9pondez%20au%20quiz%20sur%20la%20s%C3%A9curit%C3%A9%20ici%20%0D%0A%0D%0Ahttps%3A%2F%2Fwww.allianz.fr%2Fqui-est-allianz%2Fnous-connaitre%2Fallianz-innove%2Fallianz-lime-partenariat.html"
            target="_blank"
          >
            <Image fixed={iconMail.childImageSharp.fixed} />
          </a>
        </div>
        <div className={styles.buttonRow}>
          <button
            className={styles.continueButton}
            onClick={() => {
              goToNextStep();
            }}
          >
            Voir le certificat
          </button>
        </div>
        <div className={styles.smallText}>*Free-ride = Course gratuite. </div>
        <div className={styles.smallText}>
          **Les casques Allianz x LIME ne sont adaptés qu’à un usage sur
          trottinette électrique et non sur e-scooter.
        </div>

        <div className={styles.footer}>
          <div className={styles.logoContainer}>
            <Image fluid={logo.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;

export const confirmationImgQuery = graphql`
  query ConfirmationImgQuery {
    iconTwitter: file(relativePath: { eq: "icon-twitter.jpg" }) {
      childImageSharp {
        fixed(width: 35) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    iconFacebook: file(relativePath: { eq: "icon-facebook.jpg" }) {
      childImageSharp {
        fixed(width: 35) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    iconMail: file(relativePath: { eq: "icon-mail.jpg" }) {
      childImageSharp {
        fixed(width: 35) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    logo: file(relativePath: { eq: "logo-color.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
