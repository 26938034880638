import { gql } from "apollo-boost";

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        quizzCompleted
        quizzCompletedAt
        optinLimePolicy
        optinLimeMarketing
        optinAllianzPolicy
        optinAllianzMarketing
        optinLimeAllianzPolicy
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        quizzCompleted
        quizzCompletedAt
        optinLimePolicy
        optinLimeMarketing
        optinAllianzPolicy
        optinAllianzMarketing
        optinLimeAllianzPolicy
        createdAt
        updatedAt
      }
    }
  }
`;
