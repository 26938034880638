import { PageProps } from "gatsby";
import { forEach } from "lodash";
import * as React from "react";
import { withCookies } from "react-cookie";
import Certificat from "../components/certificat/Certificat";
import Confirmation from "../components/confirmation/Confirmation";
import Finalisation from "../components/finalisation/Finalisation";
import Inscription from "../components/inscription/Inscription";
import Question from "../components/question/Question";
import styles from "./index.module.scss";

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  cookies: any;
}

const index = (props: IndexPageProps & PageProps) => {
  const [step, setStep] = React.useState<number>(20);
  const [userData, setUserData] = React.useState<any>("");

  const { cookies } = props;

  const setCookies = (name: string, value: any, path: string) => {
    cookies.set(name, value, {
      path,
    });
  };

  ////////////////////////////////////////////////////// COOKIE

  React.useEffect(() => {
    forEach(userData, (value, key) => {
      const keyStr = key.toString();
      setCookies(keyStr, value, "/");
    });
  }, [userData]);

  React.useEffect(() => {
    if (step !== 0) setCookies("progressionStep", step, "/");
  }, [step]);

  React.useEffect(() => {
    if (parseInt(cookies.cookies.progressionStep, 10) > 0) {
      setStep(parseInt(cookies.cookies.progressionStep, 10));
    } else {
      setStep(0);
    }
    const userDataFromCookie = {
      ...cookies.cookies,
    };
    delete userDataFromCookie.progressionStep;
    setUserData(userDataFromCookie);
  }, []);

  return (
    <div className={styles.container}>
      {step === 0 && (
        <Inscription
          goToNextStep={() => setStep(step + 1)}
          setUserData={(userData: any) => setUserData(userData)}
        />
      )}

      {step === 1 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Est-il recommandé de porter un casque en toutes circonstances lorsque vous conduisez une trottinette électrique ?"
          }
          answers={["Oui", "Non"]}
          rightAnswerIndex={0}
          messageOk={
            "Bonne réponse ! Les casques réduisent les traumatismes crâniens en cas d’accident. Que ce soit pour un court trajet ou pour explorer la ville, il est fortement recommandé de porter un casque pour utiliser une trottinette électrique."
          }
          messageNotOK={
            "Mauvaise réponse. Les casques sont cool à porter et vous protègent !"
          }
          motivation={" Essayez à nouveau."}
        />
      )}

      {step === 2 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Pouvez-vous conduire une trottinette électrique après avoir bu ?"
          }
          answers={["Oui", "Non"]}
          rightAnswerIndex={1}
          messageOk={
            "Bravo ! L’alcool altère votre capacité à conduire une trottinette électrique. Vous ne devez jamais monter sur une trottinette en état d'ébriété. En France, la conduite en état d'ébriété est illégale."
          }
          messageNotOK={
            "Boire et conduire est dangereux pour vous et les autres sur la route."
          }
          motivation={" Essayez à nouveau."}
        />
      )}

      {step === 3 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Combien de personnes sont autorisées à monter sur une trottinette électrique ?"
          }
          answers={["1", "2", "3", "4"]}
          rightAnswerIndex={0}
          messageOk={
            "Parfait ! Un seul et unique utilisateur par trottinette Lime."
          }
          messageNotOK={
            "Mauvaise réponse. Vous pouvez utiliser la fonction «Group ride» pour vous balader à plusieurs. Cette fonction permet d'utiliser, en toute légalité, plusieurs trottinettes électriques simultanément, via un seul et même compte utilisateur."
          }
          motivation={" Essayez à nouveau."}
        />
      )}

      {step === 4 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Avant de monter sur une trottinette électrique, que devez-vous faire ?"
          }
          answers={[
            "Inspecter la trottinette pour détecter les détériorations visibles",
            "Vérifier les freins",
            "Assurez-vous que les pneus sont en bon état",
            "Toutes les réponses ci-dessus",
          ]}
          rightAnswerIndex={3}
          messageOk={"Bravo !"}
          messageNotOK={
            "Vous devez effectivement faire cela, mais il y a d'autres choses à vérifier !"
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 5 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={"Comment descendre une rue en pente en toute sécurité ?"}
          answers={[
            "Restez vigilant face aux dangers de la route",
            "Profitez de la balade",
            "Ralentir",
            "Toutes les réponses ci-dessus",
          ]}
          rightAnswerIndex={3}
          messageOk={"Bravo !"}
          messageNotOK={
            "Oui en effet, mais il y a d'autres choses que vous devriez faire aussi !"
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 6 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Comment traverser en toute sécurité une voie ferrée ou de tramway ?"
          }
          answers={[
            "Traverser les rails de façon perpendiculaire pour éviter les chutes",
            "Ralentir et s’assurer qu’il n’y a aucun train à l’approche",
            "Toutes les réponses ci-dessus",
          ]}
          rightAnswerIndex={2}
          messageOk={"Bravo !"}
          messageNotOK={
            "Oui, mais il y a d'autres choses que vous devriez faire !"
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 7 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Quelles mesures vous aideraient à rouler en trottinette électrique en toute sécurité la nuit ou par mauvais temps ?"
          }
          answers={[
            "Porter des vêtements réfléchissants",
            "Planifier votre trajet avant de partir",
            "Assurez-vous que les lumières de votre trottinette fonctionnent",
            "Soyez particulièrement vigilant et conscient de votre environnement",
            "Toutes les réponses ci-dessus",
          ]}
          rightAnswerIndex={4}
          messageOk={"Bravo !"}
          messageNotOK={
            "Oui, mais il y a d'autres choses que vous devriez faire !"
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 8 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Quand vous êtes sur la route, conduisez-vous dans le sens de circulation des voitures ou à contre-sens ?"
          }
          answers={[
            "Dans le sens de circulation des voitures (sauf indication contraire de la réglementation routière locale)",
            "À contre-sens de la circulation",
          ]}
          rightAnswerIndex={0}
          messageOk={
            "Oui. Si vous roulez sur la route (si autorisé dans votre ville), faites-le dans le sens de la circulation des voitures."
          }
          messageNotOK={
            "Non, conduisez toujours dans le sens de circulation des voitures."
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 9 && (
        <Question
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "Quand vous roulez près des voitures garées, combien d’espace devez-vous laisser entre vous et les voitures ?"
          }
          answers={[
            "Une longueur de bras",
            "Une longueur de portière de voiture",
          ]}
          rightAnswerIndex={1}
          messageOk={
            "Oui, gardez beaucoup d'espace au cas où la portière s'ouvrirait."
          }
          messageNotOK={"Non, vous devez laisser plus distance."}
          motivation={" Essayez encore."}
        />
      )}

      {step === 10 && (
        <Question
          userData={userData}
          setUserData={(userData: any) => setUserData(userData)}
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
          step={step}
          question={
            "De quel danger routier devez-vous être conscient lorsque vous roulez ?"
          }
          answers={[
            "Nids de poule",
            "Surfaces métalliques et peintes",
            "Surfaces inégales",
            "Verglas et flaques d’eau",
            "Toutes les réponses ci-dessus",
          ]}
          rightAnswerIndex={4}
          messageOk={"Oui, bien joué !"}
          messageNotOK={
            "Vous devez faire attention à cela, mais il y a d’autres choses que vous devez également surveiller."
          }
          motivation={" Essayez encore."}
        />
      )}

      {step === 11 && <Confirmation goToNextStep={() => setStep(step + 1)} />}

      {step === 12 && <Certificat userData={userData} />}

      {step === 20 && <Finalisation />}
    </div>
  );
};
export default withCookies(index);

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
