import { useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import { capitalize } from "lodash";
import * as moment from "moment";
import * as React from "react";
import styles from "./Certificat.module.scss";

export interface IConfirmationProps {
  userData?: any;
}

const Confirmation = ({ userData }: IConfirmationProps) => {
  const { termine, logo } = useStaticQuery(certificatImgQuery);

  console.log(userData);
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div>
          <div className={styles.title}>
            {userData
              ? `${capitalize(userData.firstName)} ${capitalize(
                  userData.lastName
                )}`
              : "XXX XXX"}
          </div>
          {/*           <div className={styles.imageContainer}>
            <Image fluid={termine.childImageSharp.fluid} />
          </div> */}
          <div className={styles.title}>
            {userData
              ? moment(userData.quizzCompletedAt).format("DD/MM/YYYY")
              : "??/??/????"}
          </div>
        </div>

        <div className={styles.logoContainer}>
          <Image fluid={logo.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.row2}>
        <div className={styles.messageContainer}>
          <div className={styles.message}>
            <div>Félicitations !</div>
            <div style={{ color: "#DADADA" }}>
              {`Vous avez réussi le quiz, roulez désormais en toute sécurité avec Allianz et Lime.`}
            </div>
          </div>
          <div className={styles.mentions}>
            Vous trouverez la liste des agences Allianz ci-dessous
          </div>
          <div>
            <a
              className={styles.mentionsLink}
              target="_blank"
              href="https://www.allianz.fr/content/dam/onemarketing/azfr/common/marque/pdf/Fichier_agents_VF.pdf"
            >
              Liste des agences Allianz participantes à l'opération
            </a>
          </div>
        </div>
        <div className={styles.buttonRow}>
          {/* <button className={styles.continueButton} onClick={() => {}}>
            Terminer
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;

export const certificatImgQuery = graphql`
  query CertificatImgQuery {
    termine: file(relativePath: { eq: "termine.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    logo: file(relativePath: { eq: "logo-color.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
